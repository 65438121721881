import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

import { ContactProfileDefinitionDTO, SystemDataApiClientService } from "../api-clients/system-data-api-client.service";
import { ModuleMapperService } from "../mappers/module-mapper.service";
import { AttributeIndex, ContactProfileDefinition } from "../model/contact-profile-definition";
import { FormElementType } from "../model/form/form-element/form-element";
import { TranslatableLabel } from "./data-catalog.service";
import { FormModule } from "../model/form/form-module";

@Injectable({
    providedIn: "root",
})
export class ModulesAttributesService {
    private _modules = new BehaviorSubject<AttributeModule[]>(undefined);
    private _baseFormattedFields = new BehaviorSubject<FormattedField[]>(undefined);

    constructor(private systemDataApi: SystemDataApiClientService, private moduleMapper: ModuleMapperService) { }

    init(): Observable<void> {
        return this._systemDataSubscription();
    }
    getContactProfileDefinition(): Observable<ContactProfileDefinition> {
        return this.systemDataApi.getDefinitions().pipe(
            map((cpd: ContactProfileDefinitionDTO) => {
                cpd.profile.attributes = cpd.profile.attributes.map((attr) => {
                    if (typeof attr.label === "string") {
                        attr.label = { fr: attr.label };
                    }
                    return attr;
                });
                return new ContactProfileDefinition({ ...cpd.profile });
            })
        );
    }

    formElementTypes(): FormElementType[] {
        return [
            "textbox",
            "radio",
            "datetime",
            "number",
            "dropdown",
            "union",
            "phone",
            "checkbox",
            "textarea",
            "imageupload",
            "videoupload",
            "fileupload",
            "boolean",
            "weight",
            "height",
        ];
    }

    getAttributeIndex(index: string): AttributeIndex {
        const modules = this._modules.value;
        return modules
            .map((m) => m.attributes)
            .reduce((acc, val) => acc.concat(val), [])
            .find((attributeIndex) => attributeIndex.index === index);
    }

    getModules(): Observable<AttributeModule[]> {
        return this._modules;
    }

    getBaseFormattedFields(): FormattedField[] {
        return this._baseFormattedFields.value;
    }

    getBaseFieldByIndex(index: string): FormattedField {
        return this._baseFormattedFields.value.find((ff) => ff.id === index);
    }

    isFieldRestricted(index: string): boolean {
        return this._baseFormattedFields.value.find((ff) => ff.id === index) ? true : false;
    }

    labelForIndex(index: string, lang: string): string {
        const field = this._baseFormattedFields.value.find((ff) => ff.id === index);
        if (field) {
            return field?.name[lang];
        }
        return "";
    }

    private _systemDataSubscription(): Observable<void> {
        return this._getModules().pipe(
            tap((modules) => {
                this._baseFormattedFields.next(
                    modules
                        .map((module) =>
                            module.attributes.map((a) => {
                                const fField: FormattedField = {
                                    id: a.index,
                                    name: this.label(a.label),
                                    parentInfo: {
                                        id: module.id,
                                        name: this.label(module.name),
                                    },
                                };
                                return fField;
                            })
                        )
                        .flat(Infinity) as FormattedField[]
                );
                const ms = modules.map((module) => {
                    module.attributes = module.attributes.map((attr) => {
                        attr.label = this.label(attr.label);
                        if ((attr as any).displayed) {
                            attr.defaultDescription = true;
                        }
                        return attr;
                    });
                    return module;
                });

                this._modules.next(ms);
            }),
            map((_) => undefined)
        );
    }

    private label(label: string | TranslatableLabel): TranslatableLabel {
        if (typeof label === "string") {
            return {
                fr: label,
            };
        }
        return label;
    }

    private _getModules(): Observable<AttributeModule[]> {
        return this.systemDataApi.getDefinitions().pipe(
            map((cpd: ContactProfileDefinitionDTO) => {
                const modules = cpd.modules
                    .map((mod) => {
                        return {
                            ...mod,
                            name: this.label(mod.name),
                            attributes: cpd.profile.attributes
                                .filter((attr) => attr.module === mod.id)
                                .sort((a, b) => a.order - b.order),
                        } as AttributeModule;
                    })
                    .sort((a, b) => a.order - b.order);

                modules.push({
                    order: Infinity,
                    name: { fr: "Autres" },
                    id: "autre",
                    attributes: cpd.profile.attributes.filter((attr) => !attr.module).sort((a, b) => a.order - b.order),
                });
                return modules;
            })
        );
    }
}

export interface AttributeModule {
    order: number;
    name: TranslatableLabel;
    id: string;
    attributes: AttributeIndex[];
}

export interface FormattedField {
    id: string;
    name: TranslatableLabel;
    // Form if field's from form or module if field's from systemData
    parentInfo: {
        id: string;
        name: TranslatableLabel;
    };
}

export interface FieldGroup {
    id: string;
    defaultOpen: boolean;
    editable?: boolean;
    isPublic?: boolean;
    order: number;
    name: TranslatableLabel;
    fields?: FieldReference[];
}

export interface FieldReference {
    id: string;
    order: number;
}
