import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { forkJoin, from, Observable, of } from 'rxjs';
import { concatMap, map, switchMap, take, tap } from 'rxjs/operators';
import {
    SendFormToContactsRequest,
    UpdateAttributesMappingRequest,
} from 'src/app/api-clients/system-request-api-client.service';
import { AuthService } from 'src/app/services/auth.service';
import { SystemRequestService } from 'src/app/services/system-request.service';
import { PublicContext } from 'src/modules/core/model/context';
import { PublicContextService } from 'src/modules/core/services/public-context.service';

import { FormApiClientService } from '../api-clients/form-api-client.service';
import { FormDTO, FormMapperService } from '../mappers/form-mapper.service';
import { FormElementDTO } from '../mappers/module-mapper.service';
import { Form } from '../model/form/form';
import { FormElement, FormElementInput } from '../model/form/form-element/form-element';
import { FormText } from '../model/form/form-element/form-text';
import { TranslatableLabel } from './data-catalog.service';
import { FormLockService } from './form-lock.service';
import { ModulesAttributesService } from './modules-attributes.service';
import { TranslateService } from './translate.service';
import { FormModule } from '../model/form/form-module';
import { ModuleService } from './module.service';

const DEFAULT_LOGO =
    "https://castingdbdevsa.blob.core.windows.net/fileinputs/a6043a-screen_shot_2021_10_11_at_11_08_08_am_png.png_thumbnail_800.jpeg";
@Injectable({
    providedIn: "root",
})
export class FormService {
    constructor(
        private formApi: FormApiClientService,
        private formMapper: FormMapperService,
        private systemRequestService: SystemRequestService,
        private authService: AuthService,
        private translateService: TranslateService,
        private modulesAttributesService: ModulesAttributesService,
        private moduleService: ModuleService,
        private formLockService: FormLockService,
        private db: AngularFirestore,
        private publicContextService: PublicContextService
    ) { }

    private defaultForm(projectId: string): Observable<Form> {
        return this.moduleService.defaultModules().pipe(take(1), map(defaultModules => {
            return new Form({
                id: this.db.createId(),
                name: { fr: "Nouveau formulaire" },
                projectId,
                logo: DEFAULT_LOGO,
                modules: defaultModules || [],
                languages: [this.translateService.lang],
                endText: { fr: "<h3>Merci !</h3><p>Votre formulaire a été envoyé.</p>", en: "<h3>Thanks !</h3><p>The form has been submitted successfully.</p>" },
                users: [this.db.collection("users").doc(this.authService.loggedInUser.id).ref],
                created: new Date()
            })
        }))

    }

    createForm(projectId: string): Observable<SaveFormResponse> {
        return this.defaultForm(projectId).pipe(
            switchMap((newForm) => {
                return this.saveApiForm(this.formMapper.formToFormDto(newForm));
            }),
            take(1)
        );
    }

    sendTemplateToContactsForProject(
        template: Form,
        projectId: string,
        url: { firstPart: string; slug: string },
        variablesDefinition: VariablesDefinition,
        contactIds: string[],
        message: string
    ): Observable<void> {
        console.warn("TO FIX")
        return of()
        // let form = this.makeACopy(template).change({ isTemplate: false });
        // form = this.replaceVariables(form, variablesDefinition).change({ projectId });
        // return this.addFormLink(form, projectId, url.slug).pipe(
        //     switchMap((_) => {
        //         return this.sendFormToContacts(contactIds, url.firstPart + url.slug, form, message);
        //     }),
        //     map((_) => undefined)
        // );
    }

    copyFormToAnotherProjectAndSendIt(
        originalForm: Form,
        contactIds: string[],
        projectId: string,
        url: { firstPart: string; slug: string },
        message: string
    ): Observable<void> {
        console.warn("TO FIX");
        return of();
        // let form = this.makeACopy(originalForm);
        // return this.addFormLink(form, projectId, url.slug).pipe(
        //     switchMap((_) => {
        //         return this.projectFormRelationships.assignFormToProject(form.id, projectId);
        //     }),
        //     switchMap(() => {
        //         return this.contextNodeService.assignFormsToProjectNode(projectId, [form.id]);
        //     }),
        //     switchMap((_) => {
        //         return this.sendFormToContacts(contactIds, url.firstPart + url.slug, form, message);
        //     }),
        //     map((_) => undefined)
        // );
    }

    publishFormAndSendIt(
        form: Form,
        contactIds: string[],
        projectId: string,
        url: { firstPart: string; slug: string },
        message: string
    ): Observable<void> {
        return this.addFormLink(form, projectId, url.slug).pipe(
            switchMap((context) => {
                return this.sendFormToContacts(contactIds, url.firstPart + url.slug, form, message);
            })
        );
    }

    sendExistingFormToContacts(
        form: Form,
        contactIds: string[],
        urlFirstPart: string,
        message: string
    ): Observable<void> {
        return this.publicContextService.contextByFormId(form.id).pipe(
            switchMap((context) => {
                return this.sendFormToContacts(contactIds, urlFirstPart + context.slug, form, message);
            })
        );
    }

    saveACopyInProject(form: Form, projectId: string, name?: TranslatableLabel): Observable<Form> {
        let formCopy = this.makeACopy(form, projectId);
        if (name) {
            formCopy = formCopy.change({ name })
        }
        return this.saveApiForm(this.formMapper.formToFormDto(formCopy)).pipe(map((r) => r.form));
    }

    private makeACopy(form: Form, projectId: string): Form {
        const name = { ...form.name };
        return form.change({
            id: this.db.createId(),
            name,
            projectId,
            isPublished: false,
            modules: form.modules
                .filter((m) => !m.deleted)
                .map((m) =>
                    m.change({
                        id: this.db.createId(),
                        formElements: m.activeFormElements.map((fe) => {
                            if (this.modulesAttributesService.isFieldRestricted(fe.name)) {
                                return fe.change({});
                            } else {
                                const options = (fe as any).options;
                                const newBuilder: FormElementDTO = {
                                    id: this.db.createId(),
                                    name: this.db.createId(),
                                };
                                if (options) {
                                    newBuilder.options = options.map((opt) => {
                                        return { ...opt, id: this.db.createId() };
                                    });
                                }

                                return fe.change(newBuilder);
                            }
                        }),
                    })
                ),
            created: new Date()
        });
    }

    private sendFormToContacts(contactIds: string[], url: string, form: Form, emailMessage: string): Observable<void> {
        return this.systemRequestService
            .add({
                contacts: contactIds,
                url,
                formId: form.id,
                requestType: "sendForm",
                emailMessage,
            } as SendFormToContactsRequest)
            .pipe(map((_) => console.log(_.id)));
    }

    saveAsTemplate(form: Form): Observable<Form> {
        const formTemplate = this.makeATemplate(form);
        return this.saveApiForm(this.formMapper.formToFormDto(formTemplate)).pipe(map((r) => r.form));
    }

    private makeATemplate(form: Form): Form {
        const name = { ...form.name };
        return this.makeACopy(form, null).change({
            name,
            isTemplate: true,
        });
    }

    forms(opts: FormQueryOptions = { archived: false, templates: false, deleted: false }): Observable<Form[]> {
        if (this.authService.isLoggedIn) {
            return this.formApi
                .forms(opts)
                .pipe(map((formsDto) => formsDto.map((formDto) => this.formMapper.formDtoToForm(formDto)).filter(_ => _)));
        }
        return of([]);
    }

    templates(): Observable<Form[]> {
        if (this.authService.isLoggedIn) {
            return this.formApi
                .forms({ templates: true, deleted: false, archived: false })
                .pipe(map((formsDto) => formsDto.map((formDto) => this.formMapper.formDtoToForm(formDto))));
        }
        return of([]);
    }

    form(id: string, options = { listen: true }): Observable<Form> {
        if (id) {
            return this.formApi.formById(id, options).pipe(
                map((formDto) => {
                    return this.formMapper.formDtoToForm(formDto);
                })
            );
        } else {
            return of(undefined);
        }
    }

    formForProject(projectId: string): Observable<Form[]> {
        return this.formApi.forms({ projectId, archived: false, deleted: false }).pipe(map(formDtos => {
            return formDtos.map(f => this.formMapper.formDtoToForm(f))
        }));
    }

    formElement(formId: string, formElementId: string): Observable<FormElement> {
        return this.form(formId).pipe(
            take(1),
            map((form) => {
                const formElement = form ? form.formElementByName(formElementId) : undefined;
                return formElement ? formElement : undefined;
            })
        );
    }

    saveTemplate(form: Form): Observable<Form> {
        if (form.isTemplate) {
            const lastUpdatedForm = form.change({ lastUpdated: new Date().getTime() / 1000 });
            const dto = this.formMapper.formToFormDto(lastUpdatedForm);
            return this.formApi.saveForm(dto).pipe(
                map((formDto) => this.formMapper.formDtoToForm(formDto)),
                concatMap((f) => {
                    return this.systemRequestService
                        .add({
                            formId: `forms/${f.id}`,
                            requestType: "updateattributesmappingswithform",
                        } as UpdateAttributesMappingRequest)
                        .pipe(
                            tap((sr) => console.log(sr.id)),
                            map((_) => f)
                        );
                }),
                take(1)
            );
        }
    }

    saveForm(
        form: Form,
        formLockHash: string,
    ): Observable<SaveFormResponse> {
        const lastUpdatedForm = form.change({ lastUpdated: new Date().getTime() / 1000 });
        const dto = this.formMapper.formToFormDto(lastUpdatedForm);

        return this.formLockService.validateLock(form.id, formLockHash).pipe(
            switchMap((validateResponse) => {
                if (validateResponse.success) {
                    return this.saveApiForm(dto);
                }
                return of({ success: false, message: "Form Lock invalid or expired" });
            })
        );
    }

    private saveApiForm(
        dto: FormDTO
    ): Observable<SaveFormResponse> {
        let obs: Observable<FormDTO>;

        obs = this.formApi.saveForm(dto);


        return obs.pipe(
            map((formDto) => {
                formDto
                return this.formMapper.formDtoToForm(formDto);
            }),
            concatMap((f) => {
                return this.systemRequestService
                    .add({
                        formId: `forms/${f.id}`,
                        requestType: "updateattributesmappingswithform",
                    } as UpdateAttributesMappingRequest)
                    .pipe(
                        tap((sr) => console.log(sr.id)),
                        map((_) => {
                            return {
                                form: f,
                                success: true,
                            };
                        })
                    );
            }),
            take(1)
        );
    }

    addFormLink(form: Form, projectId: string, urlSlug: string): Observable<PublicContext> {
        return this.formApi.saveForm(this.formMapper.formToFormDto(form.publish())).pipe(
            switchMap((r) => {
                return this.publicContextService.registerNewContextForForm(urlSlug, form, projectId);
            })
        );
    }

    replaceVariables(form: Form, variablesDefinition: VariablesDefinition): Form {
        return form.change({
            name: this.replaceVariablesInTranslatableLabel(form.name, variablesDefinition),
            endText: this.replaceVariablesInTranslatableLabel(form.endText, variablesDefinition),
            modules: form.modules.map((m) =>
                m.change({
                    formElements: m.formElements.map((fe) => {
                        if (fe.isInput()) {
                            return (fe as FormElementInput).change({
                                label: this.replaceVariablesInTranslatableLabel(
                                    (fe as FormElementInput).label,
                                    variablesDefinition
                                ),
                                description: this.replaceVariablesInTranslatableLabel(
                                    (fe as FormElementInput).description,
                                    variablesDefinition
                                ),
                                nameDescriptor: this.replaceVariablesInTranslatableLabel(
                                    (fe as FormElementInput).nameDescriptor,
                                    variablesDefinition
                                ),
                            });
                        } else if (fe.type === "text") {
                            return (fe as FormText).change({
                                text: this.replaceVariablesInTranslatableLabel(
                                    (fe as FormText).text,
                                    variablesDefinition
                                ),
                            });
                        } else {
                            return fe;
                        }
                    }),
                })
            ),
        });
    }

    archiveForm(form: Form): Observable<void> {
        return from(this.db.collection("forms").doc(form.id).update({ archived: true }));
    }

    unarchiveForm(form: Form): Observable<void> {
        return from(this.db.collection("forms").doc(form.id).update({ archived: false }));
    }

    deleteForm(form: Form): Observable<void> {
        return from(this.db.collection("forms").doc(form.id).update({ deleted: true }));
    }

    private replaceVariablesInTranslatableLabel(
        label: TranslatableLabel,
        variablesDefinition: VariablesDefinition
    ): TranslatableLabel {
        Object.keys(label).forEach((lang) => {
            Object.keys(variablesDefinition).forEach((slug) => {
                label[lang] = label[lang].replace(`[${slug}]`, variablesDefinition[slug][lang]);
            });
        });
        return label;
    }
}

export interface SaveFormResponse {
    success: boolean;
    message?: string;
    form?: Form;
}

export interface FormQueryOptions {
    archived?: boolean;
    deleted?: boolean;
    templates?: boolean;
    projectId?: string
}

export interface VariablesDefinition {
    [k: string]: TranslatableLabel;
}
