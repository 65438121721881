import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentChangeAction, DocumentData } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { AddAttributeReferenceRequest } from 'src/app/api-clients/system-request-api-client.service';
import { calculateAge, heightLiteral, isDate, isString, readableDate, weightLiteral } from 'src/app/core/functions';
import { SystemRequestService } from 'src/app/services/system-request.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';

import { FormElement, FormElementType } from '../model/form/form-element/form-element';
import { FormOption } from '../model/form/form-element/form-option';
import { FormService } from './form.service';
import { ModuleService } from './module.service';
import { TranslateService } from './translate.service';

@Injectable({
    providedIn: "root",
})
export class DataCatalogService {
    private _attributeLabels = new Map<string, BehaviorSubject<AttributeLabel>>();

    private readonly specificValueStrategy = new Map<string, (attr: AttributeLabel, value: any) => string>();
    private readonly litteralStrategies = new Map<NativeType, (value: any) => string>();
    private readonly arrayResponseStrategies = new Map<string, (attr: AttributeLabel, value: any[]) => string>();
    private disposeBag = new DisposeBag();

    constructor(
        private systemRequestService: SystemRequestService,
        private formService: FormService,
        private moduleService: ModuleService,
        private translateService: TranslateService,
        private db: AngularFirestore
    ) {
        this.litteralStrategies.set("phone", this.phoneStrategy);
        this.litteralStrategies.set("date", this.dateStrategy);
        this.litteralStrategies.set("union", this.unionStrategy);
        this.litteralStrategies.set("availability", this.availabilityStrategy);

        this.arrayResponseStrategies.set("string-array", (attr, value) => this.stringArrayStrategy(attr, value));
        this.arrayResponseStrategies.set("id-value-object-array", (attr, value) =>
            this.idValueObjectArrayStrategy(attr, value)
        );
        this.arrayResponseStrategies.set("availabilities-array", (attr, value) =>
            this.availabilitiesArrayStrategy(attr, value)
        );

        this.specificValueStrategy.set("identity_dateOfBirth", this.ageStrategy);
        this.specificValueStrategy.set("measurements_height_metric", this.heightStrategy);
        this.specificValueStrategy.set("measurements_weight_metric", this.weightStrategy);
    }


    addAttributeLabel(label: TranslatableLabel, type: FormElementType): Observable<AttributeLabel> {
        const id = this.db.createId();
        const references: AttributeReference[] = [
            {
                label: label.en,
                language: "en",
                source: "manualEntry",
            },
            {
                label: label.fr,
                language: "fr",
                source: "manualEntry",
            },
        ];

        return this.systemRequestService
            .add({
                requestType: "addAttributeReference",
                attributeId: id,
                formElementType: type,
                references,
            } as AddAttributeReferenceRequest)
            .pipe(
                switchMap((_) => {
                    return this.attributeLabel(id).pipe(filter((attr) => attr.hasReferences()));
                })
            );
    }

    attributeLabel(attributeId: string): Observable<AttributeLabel> {

        if (!this._attributeLabels.has(attributeId)) {
            this._attributeLabels.set(attributeId, new BehaviorSubject<AttributeLabel>(undefined));
            this.db
                .collection("dataCatalog")
                .doc(attributeId)
                .collection("references", (ref) => ref.orderBy("date").limit(1))
                .snapshotChanges()
                .pipe(
                    map((d: DocumentChangeAction<DocumentData>[]) => {
                        return d.map((subDoc) => {
                            const data: any = subDoc.payload.doc.data();
                            const attrRef = {
                                ...data,
                                id: subDoc.payload.doc.id,
                                date: data.date?.toDate() || null,
                            } as AttributeReference;
                            return attrRef;
                        });
                    }),
                    map((references: AttributeReference[]) => {
                        const attrLabel = new AttributeLabel({
                            attributeId,
                            references,
                        });
                        return attrLabel;
                    })
                ).pipe(map(attrLabel => {
                    this._attributeLabels.get(attributeId).next(attrLabel);
                })).subscribe().disposedBy(this.disposeBag)

        }
        return this._attributeLabels
            .get(attributeId)
            .asObservable()
            .pipe(filter((attrLabel) => (attrLabel ? true : false)));
    }

    attributeValue(attributeId: string, value: any, maxCharacters?: number): Observable<string> {
        let obs;
        if (attributeId && attributeId.startsWith("recruitment_")) {
            const formId = attributeId.substring(attributeId.indexOf("_") + 1);
            obs = this.formService.form(formId, { listen: false }).pipe(map((f) => f.name[this.translateService.lang]));
        } else {
            if (attributeId) {
                obs = this.attributeLabel(attributeId).pipe(
                    map((attr) => {
                        return this.litteralValueForAttributeLabel(attr, value)
                    })
                );
            } else {
                obs = of(value);
            }
        }

        return obs.pipe(
            map((value: string) => {
                let finalValue: string = value || "";
                if (maxCharacters && finalValue.length > maxCharacters) {
                    finalValue = finalValue.slice(0, maxCharacters) + "...";
                }
                return finalValue;
            })
        );
    }

    litteralValueForAttributeLabel(attr: AttributeLabel, value: any): string {
        const specificLabelStrategy = this.specificValueStrategy.get(attr.attributeId);

        if (specificLabelStrategy) {
            return specificLabelStrategy(attr, value);
        } else {
            return this.defaultLabelStrategy(attr, value);
        }
    }

    attributeFormElement(attributeId: string): Observable<FormElement> {
        return this.attributeLabel(attributeId).pipe(
            switchMap((attr) => {
                if (attr.hasReferences()) {
                    if (attr.hasConfigSource()) {
                        return of(this.moduleService.formElementForIndex(attributeId));
                    }
                    const formSources = attr.hasFormSources();
                    if (formSources) {
                        return this.formService.form(formSources[0].formId, { listen: false }).pipe(
                            map((f) => {
                                return f.formElementByName(attributeId);
                            })
                        );
                    }

                    const manualEntrySources = attr.hasManualEntrySources();
                    if (manualEntrySources) {
                        // return this.moduleService.defaultFormElementForFormElementType(
                        //     manualEntrySources[0].formElementMetaData.formElementType
                        // );
                    }
                }
                return of(undefined);
            })
        );
    }

    private stringArrayStrategy(attr: AttributeLabel, value: any[]): string {
        if (attr) {
            return value
                .filter((v) => {
                    return attr && attr.hasOptionLabels() && attr.value(v);
                })
                .map((v) => {
                    return attr.value(v)[this.translateService.lang];
                })

                .join(", ");
        } else {
            return value.join(", ");
        }
    }
    private idValueObjectArrayStrategy(attr: AttributeLabel, value: any[]): string {
        return value
            .filter((v) => v.value && attr.hasOptionLabels())
            .map((v) => attr.value(v.id)[this.translateService.lang])
            .join(", ");
    }

    private availabilitiesArrayStrategy(attr: AttributeLabel, value: any[]): string {
        return value
            .filter((v) => v.from && v.to)
            .map((v) => `${readableDate(v.from, "dd-MM-yyyy")} - ${readableDate(v.to, "dd-MM-yyyy")}`)
            .join(", ");
    }

    private defaultLabelStrategy(attr: AttributeLabel, value: any): string {
        if (Array.isArray(value)) {

            return this.multipleResponseStrategy(attr, value);
        } else {
            if (attr && typeof value === "string" && attr.hasOptionLabels() && attr.value(value)) {
                const v = attr.value(value);
                return v[this.translateService.lang]
                    ? v[this.translateService.lang]
                    : v["default"]
                        ? v["default"]
                        : value;
            } else {
                const litteralStrategy = this.litteralStrategies.get(this.typeForValue(value));
                return litteralStrategy ? litteralStrategy(value) : value;
            }
        }
    }

    private multipleResponseStrategy(attr: AttributeLabel, value: any[]): string {
        const arrayResponseStrategy = this.arrayResponseStrategies.get(this.typeForValues(value));
        if (arrayResponseStrategy) {
            return arrayResponseStrategy(attr, value);
        } else {
            console.warn(`Need to define a display strategy for attribute values array`, value);
            return;
        }
    }

    private typeForValues(value: any[]): ArrayResponseType {
        if (value.every((v) => isString(v))) {
            return "string-array";
        } else if (value.every((v) => v.hasOwnProperty("id") && v.hasOwnProperty("value"))) {
            return "id-value-object-array";
        } else if (value.every((v) => v.hasOwnProperty("from") && v.hasOwnProperty("to"))) {
            return "availabilities-array";
        }
    }

    private typeForValue(value: any): NativeType {
        if (typeof value === "string") {
            return "string";
        } else if (typeof value === "number") {
            return "number";
        } else if (isDate(value)) {
            return "date";
        } else if (typeof value === "boolean") {
            return "boolean";
        } else if (
            value &&
            typeof value === "object" &&
            value.hasOwnProperty("areaCode") &&
            value.hasOwnProperty("localSuffix") &&
            value.hasOwnProperty("localPrefix")
        ) {
            return "phone";
        } else if (value && typeof value === "object" && value.hasOwnProperty("id") && value.hasOwnProperty("prefix")) {
            return "union";
        } else if (value && typeof value === "object" && value.hasOwnProperty("from") && value.hasOwnProperty("to")) {
            return "availability";
        }
    }

    private ageStrategy(_, value: any): string {
        if (!value) {
            return "";
        }
        if (Array.isArray(value)) {
            return `${calculateAge(value[1])} - ${calculateAge(value[0])}`;
        } else {
            return `${calculateAge(value)} (${readableDate(value)})`;
        }
    }

    private phoneStrategy(value: any): string {
        if (value && value.areaCode && value.localPrefix && value.localSuffix) {
            return `(${value?.areaCode}) ${value?.localPrefix}-${value?.localSuffix}`;
        }
        return "";
    }
    private dateStrategy(value: any): string {
        return readableDate(value);
    }
    private unionStrategy(value: any): string {
        if (value?.prefix && value.prefix !== "") {
            return `${value?.prefix || ""}-${value?.id || ""}`;
        }
        return value.id;
    }
    private availabilityStrategy(value: any): string {
        return value
            .map((v) => {
                return `${readableDate(v?.from, "dd-MM-yyyy")} - ${readableDate(v?.to, "dd-MM-yyyy")}`;
            })
            .join("**");
    }

    private heightStrategy(attr: AttributeLabel, value: number | number[]): string {
        if (Array.isArray(value) && value.length === 2) {
            return `${heightLiteral(value[0])} - ${heightLiteral(value[1])}`;
        } else {
            return heightLiteral(value as number);
        }
    }
    private weightStrategy(attr: AttributeLabel, value: number): string {
        if (Array.isArray(value) && value.length === 2) {
            return `${weightLiteral(value[0])} - ${weightLiteral(value[1])}`;
        } else {
            return weightLiteral(value);
        }
    }
}

export interface AttributeLabelBuilder {
    attributeId: string;
    references: AttributeReference[];
}

export class AttributeLabel {
    private _attributeId: string;
    private _references: AttributeReference[];

    // CONFIG VALUES FROM THE ADMIN MODULES
    private _configLabels: { [P: string]: string } = {};
    private _configReferencesByLang: { [P: string]: AttributeReference } = {};

    //MANUAL ENTRIES BY LANG
    private _manualEntryReferencesByLang: { [P: string]: string } = {};

    // POSSIBLE VALUES FROM FORM
    private _possibleLabelsByValue: any = {};

    // FORM LABELS
    private _allFormReferences: AttributeReference[] = [];
    private _formReferencesByLang: { [P: string]: AttributeReference[] } = {};
    private _formReferencesByFormId: { [P: string]: AttributeReference[] } = {};

    constructor(builder: AttributeLabelBuilder) {
        this._attributeId = builder.attributeId;

        this._references = builder.references || [];
        this._allFormReferences = this._references.filter((r) => r.source === "forms");

        this._references.forEach((r) => {
            if (r.referenceType !== "possibleValue") {
                if (r.source === "config") {
                    this._configReferencesByLang[r.language] = r;
                    this._configLabels[r.language] = r.label;
                }

                if (r.source === "manualEntry") {
                    this._manualEntryReferencesByLang[r.language] = r.label;
                }
            }
        });

        this._allFormReferences.forEach((r) => {
            if (r.referenceType !== "possibleValue") {
                if (this._formReferencesByLang[r.language]) {
                    this._formReferencesByLang[r.language].push(r);
                } else {
                    this._formReferencesByLang[r.language] = [r];
                }

                if (this._formReferencesByFormId[r.formId]) {
                    this._formReferencesByFormId[r.formId].push(r);
                } else {
                    this._formReferencesByFormId[r.formId] = [r];
                }
            } else {
                if (r.value) {
                    if (!this._possibleLabelsByValue[r.value]) {
                        this._possibleLabelsByValue[r.value] = {};
                    }
                    this._possibleLabelsByValue[r.value][r.language || "default"] = r.label;
                }
            }
        });
    }

    get attributeId(): string {
        return this._attributeId;
    }

    label(lang = TranslateService.DEFAULT_LANG): string {
        if (this.attributeId.startsWith("recruitment_")) {
            if (lang === "fr") {
                return "Formulaire"
            } else {
                return "Form"
            }
        }

        const label = this._configLabels[lang] || this._manualEntryReferencesByLang[lang];
        if (label) {
            return label;
        } else if (Object.keys(this._configLabels).length > 0) {
            return this._configLabels[Object.keys(this._configLabels)[0]];
        } else {
            if (this._formReferencesByLang[lang]?.length > 0) {
                return this._formReferencesByLang[lang][0].label;
            } else if (this._allFormReferences.length > 0) {
                return this._allFormReferences[0].label;
            } else {
                return this.attributeId;
            }
        }
    }

    hasReferences(): boolean {
        return this._references.length > 0;
    }

    value(value: string): TranslatableLabel {
        return this._possibleLabelsByValue && this._possibleLabelsByValue[value]
            ? this._possibleLabelsByValue[value]
            : value;
    }

    labelForForm(formId: string, lang = TranslateService.DEFAULT_LANG): string {
        const langForFormId = this._formReferencesByFormId[formId].find((r) => r.language === lang);
        return langForFormId?.label || this._formReferencesByFormId[formId].length > 0
            ? this._formReferencesByFormId[formId][0].label
            : this._attributeId;
    }

    hasConfigSource(): AttributeReference | false {
        const configSource = this._references.find((r) => r.source === "config");
        return configSource ? configSource : false;
    }

    hasFormSources(): AttributeReference[] | false {
        const firstFormSources = this._references.filter((r) => r.source === "forms");
        return firstFormSources && firstFormSources.length > 0 ? firstFormSources : false;
    }

    hasManualEntrySources(): AttributeReference[] | false {
        const firstManualEntrySource = this._references.filter((r) => r.source === "manualEntry");
        return firstManualEntrySource && firstManualEntrySource.length > 0 ? firstManualEntrySource : false;
    }

    hasOptionLabels(): boolean {
        return Object.keys(this._possibleLabelsByValue).length > 0;
    }
}

export interface AttributeReference {
    date?: Date;
    formElementId?: string;
    formElementMetaData?: FormElementMetaData;
    formId?: string;
    label?: string;
    value?: string;
    language?: string;
    referenceType?: AttributeReferenceType;
    source?: AttributeReferenceSource;
}

export type ArrayResponseType = "string-array" | "id-value-object-array" | "availabilities-array";
export type AttributeReferenceSource = "forms" | "config" | "manualEntry" | "possibleValue";
export type AttributeReferenceType = "possibleValue" | "defaultLabel" | "translation";
export type NativeType = "string" | "number" | "date" | "phone" | "boolean" | "union" | "availability";

export interface TranslatableLabel {
    en?: string;
    fr?: string;
    // and potentially all languages
}

export interface FormElementMetaData {
    formElementType: FormElementType;
    options: FormOption[];
}
