import { NgModule } from '@angular/core';
import { CdsModule } from '@cds/angular';
import { AngularSplitModule } from 'angular-split';
import { NgxColorsModule } from 'ngx-colors';
import { BookmarkSelectorComponent } from 'src/modules/diversite/components/bookmark-selector/bookmark-selector.component';

import { CoreModule } from '../core/core.module';
import { FilloutModule } from '../fillout/fillout.module';

import { AttributeLabelComponent } from './components/attribute-label/attribute-label.component';
import { AttributeMultiselectComponent } from './components/attribute-multiselect/attribute-multiselect.component';
import { AttributeNodeComponent } from './components/attribute-node/attribute-node.component';
import { AttributeNodesComponent } from './components/attribute-nodes/attribute-nodes.component';
import { AttributeValueComponent } from './components/attribute-value/attribute-value.component';
import { BaseAttributesSearchComponent } from './components/base-attributes-search/base-attributes-search.component';
import { BookmarkComponent } from './components/bookmark-selector/bookmark/bookmark.component';
import { CalendarAvailabilitiesComponent } from './components/calendar-availabilities/calendar-availabilities.component';
import {
    ContactPerspectiveSelectionComponent,
} from './components/contact-perspective-selection/contact-perspective-selection.component';
import { ContactsMassEditComponent } from './components/contacts-mass-edit/contacts-mass-edit.component';
import { ContactCardComponent } from './components/contacts/contact-card/contact-card.component';
import {
    ContactChangeHistoryComponent,
} from './components/contacts/contact-change-history/contact-change-history.component';
import { ContactContainerComponent } from './components/contacts/contact-container/contact-container.component';
import {
    ContactEditContainerComponent,
} from './components/contacts/contact-edit-container/contact-edit-container.component';

import {
    ContactSearchProfileComponent,
} from './components/contacts/contact-search-profile/contact-search-profile.component';
import {
    SearchProfileAttributeComponent,
} from './components/contacts/contact-search-profile/search-profile-attribute/search-profile-attribute.component';
import { ContactViewComponent } from './components/contacts/contact-view/contact-view.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { NativeValueEditionComponent } from './components/contacts/native-value-edition/native-value-edition.component';
import {
    SuppressContactPromptComponent,
} from './components/contacts/suppress-contact-prompt/suppress-contact-prompt.component';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';
import {
    ContextNodeSearchOperationsComponent,
} from './components/context-node-search-operations/context-node-search-operations.component';
import {
    ContextNodeContactsComponent,
} from './components/context-node/context-node-contacts/context-node-contacts.component';
import { ContextNodeFormsComponent } from './components/context-node/context-node-forms/context-node-forms.component';
import { ContextNodeReportsComponent } from './components/context-node/context-node-reports/context-node-reports.component';
import {
    ContextNodeSharedPackagesComponent,
} from './components/context-node/context-node-shared-packages/context-node-shared-packages.component';
import { ContextNodeComponent } from './components/context-node/context-node.component';
import {
    SearchParameterEnablerComponent,
} from './components/context-node/search-parameter-enabler/search-parameter-enabler.component';
import { MainDashboardComponent } from './components/dashboard/main-dashboard/main-dashboard.component';
import {
    ActionsPaneContentContainerComponent,
} from './components/dashboard/pane/actions-pane-content-container/actions-pane-content-container.component';
import {
    AddContactActionComponent,
} from './components/dashboard/pane/actions-pane-content-container/add-contact-action/add-contact-action.component';
import {
    AddIdfigListActionComponent,
} from './components/dashboard/pane/actions-pane-content-container/add-idfig-list-action/add-idfig-list-action.component';
import {
    DownloadBundleActionComponent,
} from './components/dashboard/pane/actions-pane-content-container/download-bundle-action/download-bundle-action.component';
import {
    EditPackageActionComponent,
} from './components/dashboard/pane/actions-pane-content-container/edit-package-action/edit-package-action.component';
import {
    ExportActionComponent,
} from './components/dashboard/pane/actions-pane-content-container/export-action/export-action.component';
import {
    MassEditActionComponent,
} from './components/dashboard/pane/actions-pane-content-container/mass-edit-action/mass-edit-action.component';
import {
    MergeContactsActionComponent,
} from './components/dashboard/pane/actions-pane-content-container/merge-contacts-action/merge-contacts-action.component';
import {
    SendFormActionComponent,
} from './components/dashboard/pane/actions-pane-content-container/send-form-action/send-form-action.component';
import {
    ContactPaneContentContainerComponent,
} from './components/dashboard/pane/contact-pane-content-container/contact-pane-content-container.component';
import {
    ContactsPaneContentContainerComponent,
} from './components/dashboard/pane/contacts-pane-content-container/contacts-pane-content-container.component';
import {
    DefaultPaneContentComponent,
} from './components/dashboard/pane/default-pane-content/default-pane-content.component';
import {
    EditFormPaneContentContainerComponent,
} from './components/dashboard/pane/edit-form-pane-content-container/edit-form-pane-content-container.component';
import {
    FormPaneContentContainerComponent,
} from './components/dashboard/pane/form-pane-content-container/form-pane-content-container.component';
import { PaneHeaderComponent } from './components/dashboard/pane/pane-header/pane-header.component';
import { PaneComponent } from './components/dashboard/pane/pane.component';
import {
    ProjectsPaneContentContainerComponent,
} from './components/dashboard/pane/projects-pane-content-container/projects-pane-content-container.component';
import {
    ProjectsPaneContentComponent,
} from './components/dashboard/pane/projects-pane-content-container/projects-pane-content/projects-pane-content.component';
import {
    ReportEditPaneContentContainerComponent,
} from './components/dashboard/pane/report-edit-pane-content-container/report-edit-pane-content-container.component';
import {
    SearchFormElementStandaloneComponent,
} from './components/dashboard/pane/search-pane-content-container/search-init/search-form-element-standalone/search-form-element-standalone.component';
import {
    SearchPaginationComponent,
} from './components/dashboard/pane/search-pane-content-container/search-pagination/search-pagination.component';
import {
    SearchPaneContentContainerComponent,
} from './components/dashboard/pane/search-pane-content-container/search-pane-content-container.component';
import {
    SearchFavoriteNodeComponent,
} from './components/dashboard/pane/search-pane-content-container/search-pane-content/search-favorite-node/search-favorite-node.component';
import {
    SearchPaneContentComponent,
} from './components/dashboard/pane/search-pane-content-container/search-pane-content/search-pane-content.component';
import {
    ClassificationFolderComponent,
} from './components/dashboard/pane/search-parameters/classification/classification-folder/classification-folder.component';
import {
    ClassificationComponent,
} from './components/dashboard/pane/search-parameters/classification/classification.component';
import {
    MainSearchInputComponent,
} from './components/dashboard/pane/search-parameters/main-search-input/main-search-input.component';
import { SearchGroupComponent } from './components/dashboard/pane/search-parameters/search-group/search-group.component';
import { SearchParametersComponent } from './components/dashboard/pane/search-parameters/search-parameters.component';

import {
    TreeviewPaneContentContainerComponent,
} from './components/dashboard/pane/treeview-pane-content-container/treeview-pane-content-container.component';
import {
    UploadPhotoContentContainerComponent,
} from './components/dashboard/pane/upload-photo-content-container/upload-photo-content-container.component';
import { DeleteProjectModalComponent } from './components/delete-project-modal/delete-project-modal.component';
import {
    FieldGroupAssociationPromptComponent,
} from './components/field-group-association-prompt/field-group-association-prompt.component';
import { FilloutSearchComponent } from './components/fillout-search/fillout-search.component';
import {
    FormFieldReferenceSearchComponent,
} from './components/form-field-reference-search/form-field-reference-search.component';
import { FormBackupsComponent } from './components/form/form-backups/form-backups.component';
import { FormEditContainerComponent } from './components/form/form-edit-container/form-edit-container.component';
import { FormEditComponent } from './components/form/form-edit/form-edit.component';
import { FormElementEditComponent } from './components/forms/form-edition/form-element-edit/form-element-edit.component';
import { EditMenuComponent } from './components/forms/form-edition/form-element-edition/edit-menu/edit-menu.component';
import {
    FormAvailabilityEditionComponent,
} from './components/forms/form-edition/form-element-edition/form-availability-edition/form-availability-edition.component';
import {
    FormCheckboxEditionComponent,
} from './components/forms/form-edition/form-element-edition/form-checkbox-edition/form-checkbox-edition.component';
import {
    FormDatetimeEditionComponent,
} from './components/forms/form-edition/form-element-edition/form-datetime-edition/form-datetime-edition.component';
import {
    FormDropdownEditionComponent,
} from './components/forms/form-edition/form-element-edition/form-dropdown-edition/form-dropdown-edition.component';
import {
    FormFileuploadEditionComponent,
} from './components/forms/form-edition/form-element-edition/form-fileupload-edition/form-fileupload-edition.component';
import {
    FormHeightEditionComponent,
} from './components/forms/form-edition/form-element-edition/form-height-edition/form-height-edition.component';
import {
    FormImageuploadEditionComponent,
} from './components/forms/form-edition/form-element-edition/form-imageupload-edition/form-imageupload-edition.component';
import {
    FormNumberEditionComponent,
} from './components/forms/form-edition/form-element-edition/form-number-edition/form-number-edition.component';
import {
    FormPhoneEditionComponent,
} from './components/forms/form-edition/form-element-edition/form-phone-edition/form-phone-edition.component';
import {
    FormRadioEditionComponent,
} from './components/forms/form-edition/form-element-edition/form-radio-edition/form-radio-edition.component';
import {
    FormTableEditionComponent,
} from './components/forms/form-edition/form-element-edition/form-table-edition/form-table-edition.component';
import {
    FormTextEditionComponent,
} from './components/forms/form-edition/form-element-edition/form-text-edition/form-text-edition.component';
import {
    FormTextareaEditionComponent,
} from './components/forms/form-edition/form-element-edition/form-textarea-edition/form-textarea-edition.component';
import {
    FormTextboxEditionComponent,
} from './components/forms/form-edition/form-element-edition/form-textbox-edition/form-textbox-edition.component';
import {
    FormUnionEditionComponent,
} from './components/forms/form-edition/form-element-edition/form-union-edition/form-union-edition.component';
import {
    FormVideouploadEditionComponent,
} from './components/forms/form-edition/form-element-edition/form-videoupload-edition/form-videoupload-edition.component';
import {
    FormVoiceuploadEditionComponent,
} from './components/forms/form-edition/form-element-edition/form-voiceupload-edition/form-voiceupload-edition.component';
import {
    FormWeightEditionComponent,
} from './components/forms/form-edition/form-element-edition/form-weight-edition/form-weight-edition.component';
import { QuickEditInputComponent } from './components/forms/form-edition/quick-edit-input/quick-edit-input.component';
import { TooltipEditComponent } from './components/forms/form-edition/tooltip-edit/tooltip-edit.component';
import { PhotoCoverComponent } from './components/photo-cover/photo-cover.component';
import { PhotoProfileComponent } from './components/photo-profile/photo-profile.component';
import { ReportsContainerComponent } from './components/reports/reports-container/reports-container.component';
import {
    SearchDefinitionTagComponent,
} from './components/search-dashboard/search-definition-tags/search-definition-tag/search-definition-tag.component';
import {
    SearchDefinitionTagsComponent,
} from './components/search-dashboard/search-definition-tags/search-definition-tags.component';
import { SearchDefinitionComponent } from './components/search-dashboard/search-definition/search-definition.component';
import { SearchFavoritesComponent } from './components/search-favorites/search-favorites.component';
import {
    SearchFormElementLoaderComponent,
} from './components/search-form-element-loader/search-form-element-loader.component';
import {
    SearchFormElementComponent,
} from './components/search-form-elements/search-form-element/search-form-element.component';
import { SearchFormElementsComponent } from './components/search-form-elements/search-form-elements.component';
import {
    SearchParametersCompactComponent,
} from './components/search-parameters-compact/search-parameters-compact.component';
import { SendMessageModalComponent } from './components/send-message/send-message-modal.component';
import { PhotoRowComponent } from './components/slideshow/photo-row/photo-row.component';
import { SlideshowComponent } from './components/slideshow/slideshow.component';
import { TextHtmlEditorComponent } from './components/text-html-editor/text-html-editor.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { VideoProfileComponent } from './components/video-profile/video-profile.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { DraggableDirective } from './directives/draggable.directive';
import { HasAccessDirective } from './directives/has-access.directive';
import { PublicContextAsyncValidatorDirective } from './directives/public-context-async-validator.directive';
import { SlugValidatorDirective } from './directives/slug-validator.directive';
import { DiversiteContainerComponent } from './diversite-container/diversite-container.component';
import { HighlightPipe } from './pipe/highlight.pipe';
import { ContextResolver } from './resolvers/context.resolver';
import { ShareProjectActionComponent } from './components/dashboard/pane/actions-pane-content-container/share-project-action/share-project-action.component';
import { ActivitiesPaneContentContainerComponent } from './components/dashboard/pane/activities-pane-content-container/activities-pane-content-container.component';
import { UploadPhotoActivityComponent } from './components/dashboard/pane/activities-pane-content-container/upload-photo-activity/upload-photo-activity.component';
import { SubmitFormActivityComponent } from './components/dashboard/pane/activities-pane-content-container/submit-form-activity/submit-form-activity.component';
import { ContactSignupActivityComponent } from './components/dashboard/pane/activities-pane-content-container/contact-signup-activity/contact-signup-activity.component';
import { ContactUpdateActivityComponent } from './components/dashboard/pane/activities-pane-content-container/contact-update-activity/contact-update-activity.component';
import { ContactSupportActivityComponent } from './components/dashboard/pane/activities-pane-content-container/contact-support-activity/contact-support-activity.component';
import { MergeProfilesActivityComponent } from './components/dashboard/pane/activities-pane-content-container/merge-profiles-activity/merge-profiles-activity.component';
import { ExportContactsActivityComponent } from './components/dashboard/pane/activities-pane-content-container/export-contacts-activity/export-contacts-activity.component';
import { ActivityComponent } from './components/dashboard/pane/activities-pane-content-container/activity/activity.component';
import { CopyFormActionComponent } from './components/dashboard/pane/actions-pane-content-container/copy-form-action/copy-form-action.component';
import { ContactSimpleProfileComponent } from './components/contacts/contact-simple-profile/contact-simple-profile.component';
import { CreateFolderActionComponent } from './components/dashboard/pane/actions-pane-content-container/create-folder-action/create-folder-action.component';
import { ReportEditComponent } from './components/reports/report-edit/report-edit.component';
import { ContactReportComponent } from './components/reports/report-edit/contact-report/contact-report.component';

@NgModule({
    declarations: [
        DiversiteContainerComponent,
        ContactCardComponent,
        ContactViewComponent,
        SlideshowComponent,
        PhotoRowComponent,
        ContactPerspectiveSelectionComponent,
        ReportsContainerComponent,
        DraggableDirective,
        ClickOutsideDirective,
        HasAccessDirective,
        SlugValidatorDirective,
        PublicContextAsyncValidatorDirective,
        SendMessageModalComponent,
        FormTextEditionComponent,
        FormRadioEditionComponent,
        FormDatetimeEditionComponent,
        FormNumberEditionComponent,
        FormDropdownEditionComponent,
        FormTextboxEditionComponent,
        FormPhoneEditionComponent,
        FormCheckboxEditionComponent,
        FormTextareaEditionComponent,
        FormFileuploadEditionComponent,
        FormVoiceuploadEditionComponent,
        EditMenuComponent,
        QuickEditInputComponent,
        TooltipEditComponent,
        FormElementEditComponent,
        FormEditContainerComponent,
        FormEditComponent,
        FormImageuploadEditionComponent,
        FormVideouploadEditionComponent,
        FormTableEditionComponent,
        BookmarkSelectorComponent,
        PhotoProfileComponent,
        VideoProfileComponent,
        ContactContainerComponent,
        ContactEditContainerComponent,
        ContactSearchProfileComponent,
        SearchFormElementsComponent,
        SearchFormElementComponent,
        FilloutSearchComponent,
        BaseAttributesSearchComponent,
        SearchDefinitionComponent,
        PhotoCoverComponent,
        DownloadBundleActionComponent,
        FormWeightEditionComponent,
        FormHeightEditionComponent,
        SuppressContactPromptComponent,
        ContactChangeHistoryComponent,
        NativeValueEditionComponent,
        SearchDefinitionTagsComponent,
        FormBackupsComponent,
        FormFieldReferenceSearchComponent,
        SearchDefinitionTagComponent,
        AttributeLabelComponent,
        AttributeValueComponent,
        FieldGroupAssociationPromptComponent,
        FormUnionEditionComponent,
        MainDashboardComponent,
        PaneHeaderComponent,
        PaneComponent,
        HighlightPipe,
        SearchPaneContentComponent,
        SearchPaneContentContainerComponent,
        DefaultPaneContentComponent,
        FormAvailabilityEditionComponent,
        CalendarAvailabilitiesComponent,
        ContactsMassEditComponent,
        SendFormActionComponent,
        AddContactActionComponent,
        MainSearchInputComponent,
        ContextNodeComponent,
        TreeviewPaneContentContainerComponent,
        ContextMenuComponent,
        FormPaneContentContainerComponent,
        EditFormPaneContentContainerComponent,
        ContactsComponent,
        ContactsPaneContentContainerComponent,
        ContactPaneContentContainerComponent,
        ReportEditPaneContentContainerComponent,
        ContextNodeFormsComponent,
        ContextNodeContactsComponent,
        ContextNodeReportsComponent,
        ProjectsPaneContentContainerComponent,
        ProjectsPaneContentComponent,
        DeleteProjectModalComponent,
        SearchParameterEnablerComponent,
        SearchParametersCompactComponent,
        ActionsPaneContentContainerComponent,
        MassEditActionComponent,
        MergeContactsActionComponent,
        AttributeNodesComponent,
        AttributeNodeComponent,
        SearchFormElementStandaloneComponent,
        SearchFavoriteNodeComponent,
        SearchParametersComponent,
        AttributeMultiselectComponent,
        SearchProfileAttributeComponent,
        SearchGroupComponent,
        SearchFavoritesComponent,
        ClassificationComponent,
        ClassificationFolderComponent,
        SearchFormElementLoaderComponent,
        ContextNodeSearchOperationsComponent,
        BookmarkComponent,
        SearchPaginationComponent,
        ContextNodeSharedPackagesComponent,
        AddIdfigListActionComponent,
        TimePickerComponent,
        UploadPhotoContentContainerComponent,
        ExportActionComponent,
        EditPackageActionComponent,
        TextHtmlEditorComponent,
        HasAccessDirective,
        ShareProjectActionComponent,
        ActivitiesPaneContentContainerComponent,
        UploadPhotoActivityComponent,
        SubmitFormActivityComponent,
        ContactSignupActivityComponent,
        ContactUpdateActivityComponent,
        ContactSupportActivityComponent,
        MergeProfilesActivityComponent,
        ExportContactsActivityComponent,
        ActivityComponent,
        CopyFormActionComponent,
        ContactSimpleProfileComponent,
        CreateFolderActionComponent,
        ReportEditComponent,
        ContactReportComponent,
    ],
    imports: [CoreModule, AngularSplitModule, FilloutModule, CdsModule, NgxColorsModule],
    providers: [ContextResolver],
    exports: [FormElementEditComponent, ReportEditComponent],
})
export class DiversiteModule { }
